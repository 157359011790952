import React, { useEffect, useMemo, useRef, useState } from 'react'
import "./carePlan.scss"
import { Form, Formik } from 'formik';
import { carePlanTemplates, setCarePlanTemplate, visitUpdate } from '../../../service/api/visit';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { log } from 'handlebars';
import SearchSelect from '../../common/updated-input/createble-search-select/search-select';

const CarePlan = ({ visitDetails = {}, updateState }) => {
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [info, setInfo] = useState({});
    const timeoutIdRef = useRef(null)
    const [templateList, setTemplateList] = useState([])

    const modules = {
        toolbar: [
          [{ 'font': [] }, { 'size': [] }], // Font and size
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }], // Headers
          [{ 'align': [] }], // Text alignment
          ['bold', 'italic', 'underline', 'strike'], // Formatting options
          [{ 'color': [] }, { 'background': [] }], // Font and background colors
          [{ 'script': 'sub' }, { 'script': 'super' }], // Subscript/Superscript
          [{ 'list': 'ordered' }, { 'list': 'bullet' }], // Lists
          [{ 'indent': '-1' }, { 'indent': '+1' }], // Indentation
          [{ 'direction': 'rtl' }], // Text direction (right-to-left)
        //   [{ 'lineheight': ['1', '1.5', '2', '2.5', '3'] }], // Line height
          ['blockquote', 'code-block'], // Blockquote and code block
          ['link', 'image', 'video'], // Links, images, videos
          ['clean'], // Remove formatting
        ],
      };
    
    const formats = [
      'font', 'size', 'header', 'align', 'bold', 'italic', 'underline', 'strike',
      'color', 'background', 'script', 'list', 'indent', 'direction', 'lineheight',
      'blockquote', 'code-block', 'link', 'image', 'video'
    ];

    const setInfoFun = (key, value) => {
        let temp = { ...info, [key]: value };
        setInfo(temp);
        clearTimeout(timeoutIdRef.current);
        timeoutIdRef.current = setTimeout(() => { (onSubmit(temp)) }, 1200);
    };

console.log(info)
    const onSubmit = async (values) => {
        updateComplaint(values)
    };

    function updateComplaint(values) {
        setIsSubmitting(true)
        visitUpdate({ carePlan: { ...values } }, visitDetails?._id)
            .then((res) => {
                if (res?.status == 200) {
                    setIsSubmitting(false);
                    updateState(res.data)
                }
            })
            .catch((error) => {
                setIsSubmitting(false);
            });
    }

    const handleKeyDown = (event) => {
        if ((event.metaKey || event.ctrlKey) && event.key === 's') {
            event.preventDefault();
            let values = {}
            values.carePlan = info?.carePlan;
            updateComplaint(values)

        }
    }

    const getCarePlanTemplate = (docId) => {
        carePlanTemplates(docId)
            .then((res) => {
                if (res?.status == 200) {
                    setTemplateList(res?.data?.carePlanTemplates)
                }
            })
            .catch((error) => {

            });
    }
    useEffect(() => {
        if (info)
            document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [info]);



    useMemo(() => {
        if (visitDetails) {
            setInfo({ details: visitDetails?.carePlan?.details });
            getCarePlanTemplate(visitDetails?.doctor)
        }

    }, [visitDetails])

  
    const handleSubmit = async (value) => {
        setCarePlanTemplate({ visitId: visitDetails?._id, templateId: value?._id })
            .then((res) => {
                if (res?.status == 200) {
                    console.log(res?.data)
                    updateState(res?.data)
                }
            })
            .catch((error) => {

            });
    }


    return (
        <div className='complaint-section'>
            {/* <div className='p-0 m-0 d-flex'>
                <SearchSelect uniqKey={1} borderColor='#13AC81' handleSubmit={handleSubmit} isSearching={false} setOptionFun={setOptionFun} options={templateList?.templates} placeHolder='Search templates'></SearchSelect>
            </div> */}
            <div className='row'>
                <div className='col-md-6'>
                    <label style={{ fontSize: "14px" }} htmlFor="exampleInputEmail1" className="form-label mt-2">
                        Care Plan
                    </label>
                </div>
                <div className='col-md-6'>
                    {/* <SearchSelect uniqKey={1} borderColor='#13AC81' handleSubmit={handleSubmit} isSearching={false} setOptionFun={{}} options={templateList} placeHolder='Search templates'></SearchSelect> */}
                </div>

            </div>
            {visitDetails && <Formik initialValues={{}} onSubmit={onSubmit}>
                {(formik) => (
                    <div className="">
                        <Form
                            action=""
                            id=""
                            className="custom-form input-section position-relative"
                            encType="multipart/form-data"
                        >
                            <div className="row  ">
                                <div className="col-6 col-md-12  mb-0 pb-0 ">
                                    <div className="">
                                        <ReactQuill  formats={formats} modules={modules} placeholder='care plan' style={{ height: "400px" }} theme="snow" value={info?.details || ""} onChange={(content) => { setInfoFun('details', content) }} />
                                    </div>
                                </div>

                            </div>

                        </Form>
                    </div>
                )}
            </Formik>}
        </div>
    )
}

export default CarePlan
