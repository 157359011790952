import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get, getWithParams } from "../../utils/fetchAPI";

const initialState = {
  dashboardAppointments: [],
  allAppointmentsCount: [],
  barChartAppointment: [],
  isLoading: false,
  isOperationLoading: false,
  error: null,
  noPatientFound: false,
};
export const getDashboardAppointments = createAsyncThunk(
  "dashboard/getDashboardAppointments",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await get(`/dashboard/latest-appointments`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getBarChartAppointment = createAsyncThunk(
  "dashboard/getBarChartAppointment",
  async (patientId, { rejectWithValue }) => {
    try {
      const response = await get(`/dashboard/daily-appointments`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllAppointmentCount = createAsyncThunk(
  "dashboard/getAllAppointmentCount",
  async (patientId, { rejectWithValue }) => {
    try {
      const response = await get(`/dashboard/appointments-by-status`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);



const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    resetAllPatients: (state, action) => {

    },
  },
  extraReducers: {
    [getDashboardAppointments.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [getDashboardAppointments.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.dashboardAppointments = action.payload;
      state.error = null;
    },
    [getDashboardAppointments.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload?.message;
    },

    [getBarChartAppointment.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [getBarChartAppointment.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.barChartAppointment = action.payload;
      state.error = null;
    },
    [getBarChartAppointment.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload?.message;
    },


    [getAllAppointmentCount.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [getAllAppointmentCount.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.allAppointmentsCount = action.payload;
      state.error = null;
    },
    [getAllAppointmentCount.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload?.message;
    },

  },
});

export default dashboardSlice.reducer;
// export const { resetAllPatients } = patientSlice.actions;
