import React, { useEffect, useMemo, useRef, useState } from 'react'
import './past-medical-history.scss'
import { useDispatch, useSelector } from 'react-redux';
import { patientAddSubDocument, patientPastMedicalHistoryAdd, patientRemoveSubDocument, patientSocialHistoryAdd, patientUpdateSubDocument } from '../../../service/api/patient-api';
import { getMedicalHistoryList, resetPastMedicalHistory } from '../../../store/slices/visitSlice';
import { LocalStorageService } from '../../../utils/local-storage';
import UniversalLoaderForComponent from '../../loader/universalLoaderForComponent';
import { post } from '../../../utils/fetchAPI';

const PastMedicalHistory = ({ patientId, visitDetails, patientDetails, updateState }) => {
    let { pastMedicalList } = useSelector(
        (state) => state.visit
    );
    const [info, setInfo] = useState({})
    const timeoutIdRef = useRef(null)
    const [patientPastMedicalHistory, setPatientPastMedicalHistory] = useState([])
    const [others, setOthers] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [searchQuery, setSearchQuery] = useState('')
    const dispatch = useDispatch()

    useEffect(() => {
        if (patientDetails?.pastMedicalHistories)
            setPatientPastMedicalHistory(patientDetails?.pastMedicalHistories)
    }, [updateState])



    const setInfoFun = (key, value, type, name) => {
        let temp = { ...info }
        if ((type === "check" && info.hasOwnProperty(key))) {
            onRemove(key)
            delete temp[key];
            setInfo(temp)
        }
        else {
            setInfo((prev) => ({ ...prev, [key]: value }));
            if ((info.hasOwnProperty(key) == false)) {
                onChangeSubmit(key, value, name)
            }
            else {
                onUpdate(key, value)
            }
        }
    };
    

    const onChangeSubmit = async (key, value, name) => {

        await patientAddSubDocument("pastMedicalHistories", { medicalDisease: key, name: name, note: value }, (visitDetails?.patient || patientId))
            .then((res) => {
                if (res?.status === 200) {
                    updateState(res?.data)
                    //setPatientPastMedicalHistory(res?.data?.patientDetails?.pastMedicalHistories)
                }
            })
            .catch((error) => {
                console.log(error);
            });

    }

    const onRemove = async (key) => {
        await patientRemoveSubDocument("pastMedicalHistories", { medicalDisease: key }, (visitDetails?.patient || patientId))
            .then((res) => {
                if (res.status === 200) {
                    updateState(res?.data)
                    //setPatientPastMedicalHistory(res?.data?.patientDetails?.pastMedicalHistories)
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const onUpdate = async (key, value) => {
        const body = {
            payload: {
                "pastMedicalHistories.$.note": value
            },
            filterKey: "pastMedicalHistories.medicalDisease",
            filterKeyValue: key
        }
        clearTimeout(timeoutIdRef.current);
        timeoutIdRef.current = setTimeout(async () => {
            await patientUpdateSubDocument(body, (visitDetails?.patient || patientId))
                .then(res => {
                    if (res.status === 200) {
                        updateState(res?.data)
                        //setPatientPastMedicalHistory(res?.data?.patientDetails?.pastMedicalHistories)
                    }
                }).catch((error) => {
                    console.log(error);
                });

        }, 500);

    }


    let listOfPastMedical = useMemo(() => {
        if (searchQuery && pastMedicalList) {
            let filterList = pastMedicalList.filter(disease => disease?.name?.toLowerCase()?.includes(searchQuery?.toLowerCase()))
            return filterList
        }

        if (patientPastMedicalHistory) {
            patientPastMedicalHistory.map((data) => {
                setInfo((prev) => ({ ...prev, [data?.medicalDisease]: data?.note }));
            })
        }
        return pastMedicalList
    }, [pastMedicalList, searchQuery, patientPastMedicalHistory])


    const addNew = async () => {
        setIsLoading(true)
        await post('/medical-disease/create', { doctor: visitDetails?.doctor, name: searchQuery })
            .then((res) => {
                if (res.status == 200) {
                    dispatch(getMedicalHistoryList())
                    setSearchQuery(searchQuery)
                }
            }).finally(() => {
                setIsLoading(false)
            })
    }


    // const handleKeyDown = (event) => {
    //     if ((event.metaKey || event.ctrlKey) && event.key === 's') {
    //         event.preventDefault();
    //         const val = LocalStorageService.get('past-medical-history')
    //     }
    // };

    // useEffect(() => {
    //     if (info)
    //         document.addEventListener('keydown', handleKeyDown);
    //     return () => {
    //         document.removeEventListener('keydown', handleKeyDown);
    //     };
    // }, [info]);


    return (
        <div className='past-medical-history-section'>
            <div className='social-history-section-header d-flex justify-content-between mb-2 border-bottom' >
                <div className='header'>
                    <p>Past medical history</p>
                </div>
                <input
                    className="past-medical-search"
                    placeholder="Search"
                    type="text"
                    onChange={(e) => {
                        setSearchQuery(e.target.value);
                    }}
                ></input>
            </div>
            <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
                {listOfPastMedical?.map((habit, index) => {
                    return <>
                        <div key={index} className='individual-social-habit py-1'>
                            <div className='d-flex'>
                                {/* checked={patientSurgeryList?.surgeries?.includes(surgery?._id) ? true : ""} */}
                                <input onChange={(e) => { setInfoFun(habit?._id, habit?.note || "", "check", habit?.name) }} checked={info.hasOwnProperty(habit?._id)} type="checkbox" id={"past-medical-" + habit?._id} name={"past-medical-" + habit?._id} />
                                <label className='ml-2 align-content-center justify-content-center my-auto' for={"past-medical-" + habit?._id}> {habit?.name}</label><br />
                            </div>
                            <div>
                                <textarea
                                    className="form-control  w-100"
                                    autoComplete="off"
                                    type="text"
                                    rows={2}
                                    id={`${habit?._id}`}
                                    placeholder={habit?.name?.slice(0, 20) + "..."}
                                    value={info[habit?._id] || ""}
                                    onChange={(e) => {
                                        setInfoFun(habit?._id, e.target.value, "input", habit?.name)
                                    }}
                                />
                            </div>
                        </div>
                    </>
                })}
                <div>
                    {listOfPastMedical?.length == 0 && !isLoading && <div className='d-flex mx-1'>
                        <p onClick={() => { addNew() }} className='px-2 mt-1' style={{ backgroundColor: "#14A67D", color: "white", cursor: "pointer", fontSize: "13px" }}>({searchQuery}) Add</p></div>}
                </div>
                {isLoading && <UniversalLoaderForComponent></UniversalLoaderForComponent>}
            </div>

        </div>
    )
}

export default PastMedicalHistory
