import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faStethoscope,
  faAddressCard,
  faGear,
  faClock,
  faExpandArrowsAlt,
  faUsers,
  faFileLines,
} from "@fortawesome/free-solid-svg-icons";
import "../app-side-bar/app-side-bar.scss";
import { useDispatch, useSelector } from "react-redux";
import { getAllergyList, getDiseaseList, getMedicalHistoryList, getSocialHabitList, getSurgeryList } from "../../store/slices/visitSlice";
import CustomModal from "../common/modal/modal";
import Patient from "../../pages/patient/patient";

function AppSidebar() {
  const [show, setShow] = useState(false)
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getSurgeryList());
    dispatch(getSocialHabitList());
    dispatch(getMedicalHistoryList());
    dispatch(getDiseaseList());
    dispatch(getAllergyList());

  }, [])
  return (
    <div className="position-relative">
      <aside
        className="main-sidebar side-bar-color elevation-4"
        style={{ width: "70px" }}
      >
        <div className="sidebar position-relative">
          {/* Sidebar user (optional) */}
          <div className="user-panel mt-2 pb-2 mb-2 ">
            <div className="info text-center"></div>
          </div>
          {/* Sidebar Menu */}
          <nav className="mt-1">
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              {/* Add icons to the links using the .nav-icon class with font-awesome or any other icon font library */}
              <li className="nav-item" style={{ position: "absolute", top: "-10%", left: "5%" }}>
                <a
                  className="nav-link"
                  data-widget="fullscreen"
                  href="#"
                  role="button"
                >
                  <FontAwesomeIcon color="#a5a8a6" icon={faExpandArrowsAlt} />
                </a>
              </li>
              <li className="nav-item mt-2">
                <NavLink
                  to="/"
                  className="nav-link custom-nav-link text-center mx-0 px-0"
                >
                  <FontAwesomeIcon
                    size="lg"
                    className="d-block mx-auto"
                    icon={faHome}
                  />
                  <small className="mt-0 pt-0  m-0 p-0 d-block"> Home </small>
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink
                  to="appointment"
                  className="nav-link custom-nav-link text-center mx-0 px-0"
                >
                  <FontAwesomeIcon
                    size="lg"
                    className="d-block mx-auto "
                    // icon={faClipboardList}
                    icon={faFileLines}
                  />
                  <small className="mt-0 pt-0  m-0 p-0 d-block">
                    {" "}
                    Appoint <br /> ments
                  </small>
                </NavLink>
              </li>

              {user?.user?.type != "staff" && <li className="nav-item">
                <NavLink
                  to="waiting-room"
                  className="nav-link text-center mx-0 px-0 custom-nav-link"
                >
                  <FontAwesomeIcon
                    size="lg"
                    className="d-block mx-auto"
                    icon={faClock}
                  />
                  <small className="mt-0 pt-0  m-0 p-0 d-block">
                    {" "}
                    Waiting <br /> Room
                  </small>
                </NavLink>
              </li>}
              <li className="nav-item">
                <span style={{ cursor: "pointer" }}
                  // to="patient"
                  onClick={() => setShow(true)}
                  className="nav-link text-center mx-0 px-0 custom-nav-link"
                >
                  <FontAwesomeIcon
                    size="lg"
                    className="d-block mx-auto"
                    icon={faUsers}

                  />
                  <small className="mt-0 pt-0  m-0 p-0 d-block">
                    {" "}
                    Patient
                  </small>
                </span>
              </li>
              {user?.user?.type !== "staff" && <li className="nav-item">
                <NavLink
                  to="template"
                  className="nav-link text-center mx-0 px-0 custom-nav-link"
                >
                  <FontAwesomeIcon
                    size="lg"
                    className="d-block mx-auto"
                    icon={faStethoscope}
                  />
                  <small className="mt-0 pt-0  m-0 p-0 d-block">Template</small>
                </NavLink>
              </li>}

              {user?.user?.type != "staff" && <li className="nav-item">
                <NavLink
                  to="/staff"
                  className="nav-link mb-0 pb-0 mx-0 px-0 text-center custom-nav-link"
                >
                  <FontAwesomeIcon
                    size="lg"
                    className="d-block mx-auto"
                    icon={faAddressCard}
                  />
                  <small className="mt-0 pt-0 d-block m-0 p-0 mb-2">
                    Staff
                  </small>
                </NavLink>
              </li>}
              <li className="nav-item text-center">
                <NavLink
                  to="my-profile"
                  className="nav-link mb-0 pb-0 mx-0 px-0 custom-nav-link"
                >
                  <FontAwesomeIcon
                    size="lg"
                    className="d-block mx-auto"
                    icon={faGear}
                  />
                  <small className="mt-0 pt-0 d-block m-0 p-0">
                    {" "}
                    Settings{" "}
                  </small>
                </NavLink>
              </li>
            </ul>
          </nav>
          {/* /.sidebar-menu */}
          {/* <div
            className={`profile ${isProfileShow ? "disable-btn" : ""} border`}
            onClick={() => {
              setIsProfileShow(true);
            }}
          >
            <p
              className={`${getName(user?.user?.name)?.length <2
                ? "single-letter-profile-name"
                : "double-letter-profile-name"
                }`}
            >
              {getName(user?.user?.name)}
            </p>
          </div> */}
        </div>
        {/* /.sidebar */}
      </aside>
      {/* <SideBarProfile
        isProfileShow={isProfileShow}
        setIsProfileShow={setIsProfileShow}
      ></SideBarProfile> */}
      <CustomModal
        size="md"
        show={show}
        isHeaderHide={true}
        setShow={setShow}
      >
        <Patient setShow={setShow}></Patient>
      </CustomModal>
    </div>
  );
}

export default AppSidebar;
