import React, { useEffect, useMemo, useState } from "react";
import DonutChart from "../../components/dashboard/donut-chart";
import "./home.scss"

import HomeAppointmentList from "../../components/dashboard/home-appointment-list";
import { useDispatch, useSelector } from "react-redux";
import { getAllAppointmentCount, getBarChartAppointment, getDashboardAppointments } from "../../store/slices/dashboardSlice";
import BarChart from "../../components/dashboard/bar-chart";




const Home = () => {
  const [isShow, setIsShow] = useState(false);
  const dispatch = useDispatch()
  const [barChartData, setBarChartData] = useState()
  const [donutChartData, setDonutChartData] = useState()
  let { isLoading, dashboardAppointments, allAppointmentsCount, barChartAppointment } = useSelector((state) => state.dashboard);

  useEffect(() => {
    dispatch(getBarChartAppointment());
    dispatch(getAllAppointmentCount());
    dispatch(getDashboardAppointments());
  }, []);


  useMemo(() => {
    if (barChartAppointment) {
      const dates = Object.keys(barChartAppointment);
      const values = Object.values(barChartAppointment);
      setBarChartData({ dates: dates, values: values })
    }
    if (allAppointmentsCount) {
      const status = Object.keys(allAppointmentsCount);
      const values = Object.values(allAppointmentsCount);
      setDonutChartData({ status: status, values: values })
    }

  }, [allAppointmentsCount, barChartAppointment])



  return (
    <section
      className="content-wrapper main-content home-section mt-0 pt-0"
      style={{ marginLeft: "69px", height: "100vh" }}
    >
      <div className="row no-gutters g-1">
        <div className="col-md-3 chart mx-auto pt-4" >
          <label className="mx-2">Total Appointments</label>
          <DonutChart barChartData={donutChartData} ></DonutChart>
        </div>
        <div className="col-md-7 mx-auto mt-4">
          <label className="mx-2">Daily Appointments</label>
          <BarChart barChartData={barChartData}></BarChart>
        </div>
      </div>
      <div className="mt-4 mx-5">
        <label className="mx-2">Recent Appointments</label>
        <HomeAppointmentList></HomeAppointmentList>
      </div>

    </section>
  );
};

export default Home;
