import React, { useEffect, useMemo, useState } from 'react'
import './appointment-filter.scss'
import filter from "../../assets/images/filter.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsRotate } from '@fortawesome/free-solid-svg-icons';
import CreatableSearchSelect from '../common/updated-input/createble-search-select/creatable-search-select';
import { getLocations, resetLocations } from '../../store/slices/locationSlice';
import { useDispatch, useSelector } from 'react-redux';
import SelectWithLocalSearch from '../common/updated-input/select-with-local-search/select-with-local-search';
import { useSearchParams } from 'react-router-dom';
import { LocalStorageService } from '../../utils/local-storage';

const AppointmentFilter = ({ locationDoctorFilterFun, filterStatusFun, setStatus, status, width, currentDate, filterDateFun, setFilterDate, filterDate }) => {
    const utcDate = new Date();
    let bdDate = utcDate.toLocaleDateString('en-US', { timeZone: 'Asia/Dhaka' });
    bdDate = bdDate.split('/')
    let date = `${bdDate[2]}-${bdDate[0].length == 1 ? ("0" + bdDate[0]) : bdDate[0]}-${bdDate[1]?.length == 1 ? ("0" + bdDate[1]) : bdDate[1]}`
    let [filterLocalData] = useState(LocalStorageService.get("appointmentFilter"))
    const utcDate1 = new Date();
    utcDate1.setDate(utcDate1.getDate() + 7);
    let bdDate1 = utcDate1.toLocaleDateString('en-US', { timeZone: 'Asia/Dhaka' });
    bdDate1 = bdDate1.split('/')
    let endDate = `${bdDate1[2]}-${bdDate1[0].length == 1 ? ("0" + bdDate1[0]) : bdDate1[0]}-${bdDate1[1]?.length == 1 ? ("0" + bdDate1[1]) : bdDate1[1]}`


    const [selectedLocation, setSelectedLocation] = useState({})
    const [locationRest, setLocationRest] = useState(false)
    let { isLoading, allLocations, noLocationFound, locationOptions } = useSelector(
        (state) => state.location
    );
    const [searchParams] = useSearchParams();
    const dispatch = useDispatch();
    const [currentLocation, setCurrentLocation] = useState('')
    const [currentDoctor, setCurrentDoctor] = useState('')
    useEffect(() => {
        dispatch(getLocations({}));
    }, []);

    const handleSubmitLocation = (option) => {
        setSelectedLocation(option)
        locationDoctorFilterFun('location', option)
        setCurrentLocation('')
    }

    const handleLocationDoctor = (option) => {
        setCurrentDoctor("")
        locationDoctorFilterFun('doctor', option)
    }


    const locationDoctorRestFun = () => {
        locationDoctorFilterFun('reset', '')
        setSelectedLocation({})
        setCurrentLocation("")
        setCurrentDoctor("")
        setLocationRest(true)
    }


    useMemo(() => {

        if (searchParams.get('location') || filterLocalData?.locationId) {

            allLocations?.map((location) => {
                if ((searchParams.get('location') || filterLocalData?.locationId) == location?._id) {
                    setCurrentLocation(location?.name)

                }
            })
        }
        if (searchParams.get('doctor') || filterLocalData?.locationId) {
            allLocations?.map((location) => {
                if ((searchParams.get('location') || filterLocalData?.locationId) == (location?._id)) {
                    setSelectedLocation(location)
                    location?.doctors?.map((doctor) => {
                        if ((searchParams.get('doctor') || filterLocalData?.doctorId) == doctor?._id) {
                            setCurrentDoctor(doctor?.name)

                        }
                    })

                }
            })
        }
    }, [searchParams.get('location'), searchParams.get('doctor'), allLocations, filterLocalData?.locationId])


    return (
        <div className='filter-section' style={{ width: width }}>
            {/* <p className='text-center heading m-0'><img className='mr-1' src={filter}></img> Filters</p> */}
            {/* <hr className='p-0 m-0 mb'></hr> */}
            <div className='px-2 date-filter border py-2 pt-2Create new appointment'>
                <p className='p-0 m-0 title text-center'>Filter  <FontAwesomeIcon onClick={() => {
                    filterDateFun('current-date', date?.slice(0, 10), endDate)
                    setCurrentLocation("")
                    setCurrentDoctor("")
                }} size='xs' className='ml-1 rest' icon={faArrowsRotate} /></p>
                <hr className='m-0 p-0 mb-2'></hr>

                <div onClick={() => {
                    if (((filterDate?.startDate?.slice(0, 10) != date?.slice(0, 10)) || (filterDate?.endDate?.slice(0, 10) != date?.slice(0, 10))))
                        filterDateFun('current-date', date?.slice(0, 10), date?.slice(0, 10))
                }}>
                    <input checked={((filterDate?.startDate?.slice(0, 10) == date?.slice(0, 10)) && (filterDate?.endDate?.slice(0, 10) == date?.slice(0, 10))) ? true : false} id='today' name="today" type='checkbox'></input>
                    <label htmlFor='today'>Todays Appointment</label>
                </div>

                <div className='row pr-1'>
                    <span className='col-4'><small>From:</small></span> <input value={filterDate?.startDate || ""} onChange={(e) => {
                        setFilterDate({ startDate: e.target.value?.slice(0, 10), endDate: filterDate?.endDate })
                        if (filterDate?.endDate) filterDateFun('between-date', e.target.value?.slice(0, 10), filterDate?.endDate)
                    }} className='col-8 pe-2 mb-2' type='date'></input>
                    <span className='col-4'><small>To</small></span> <input min={filterDate?.startDate?.slice(0, 10)} value={filterDate?.endDate?.slice(0, 10) || ""} onChange={(e) => {
                        setFilterDate({ startDate: filterDate?.startDate, endDate: e.target.value?.slice(0, 10) })
                        if (filterDate?.startDate) filterDateFun('between-date', filterDate?.startDate, e.target.value?.slice(0, 10))
                    }} className='col-8' type='date'></input>
                </div>
            </div>
            <div className='px-2 date-filter border py-2'>
                <p className='p-0 m-0 title text-center'>Status Filter {status && <FontAwesomeIcon onClick={() => filterStatusFun('reSet')} size='xs' className='ml-1 rest' icon={faArrowsRotate} />}</p>
                <hr className='m-0 p-0 mb-2'></hr>
                <div className='' onChange={() => {
                    setStatus("present")
                    if (status != "present") filterStatusFun("present")
                    else if (status == "present") filterStatusFun('reSet')
                }
                }>
                    <input checked={status == "present" ? true : false} id='present' name="present" type='checkbox'></input>
                    <label htmlFor='present'>Present</label>
                </div>
                <div className='' onChange={() => {
                    setStatus("pending")
                    if (status != "pending") filterStatusFun("pending")
                    else if (status == "pending") filterStatusFun('reSet')

                }}>
                    <input checked={status == "pending" ? true : false} id='Pending' name="Pending" type='checkbox'></input>
                    <label htmlFor='Pending'>Pending</label>
                </div>
                <div className='' onChange={() => {
                    setStatus("completed")
                    if (status != "completed") filterStatusFun("completed")
                    else if (status == "completed") filterStatusFun('reSet')
                }}>
                    <input checked={status == "completed" ? true : false} id='Completed' name="Completed" type='checkbox'></input>
                    <label htmlFor='Completed'>Completed</label>
                </div>
                <div className='' onChange={() => {
                    setStatus("canceled")
                    if (status != "canceled") filterStatusFun("canceled")
                    else if (status == "canceled") filterStatusFun('reSet')
                }}>
                    <input checked={status == "canceled" ? true : false} id='Canceled' name="Canceled" type='checkbox'></input>
                    <label htmlFor='Canceled'>Canceled</label>
                </div>
            </div>

            <div className='px-2 date-filter border py-2'>
                <p className='p-0 m-0 title text-center'>Location & Doctor Filter {(selectedLocation?._id || searchParams.get('doctor')) && <FontAwesomeIcon onClick={() => {
                    locationDoctorRestFun()
                }
                } size='xs' className='ml-1 rest' icon={faArrowsRotate} />}</p>
                <hr className='m-0 p-0 mb-2'></hr>
                <div onClick={() => { setLocationRest(false) }}>
                    <small>Location</small>
                    <SelectWithLocalSearch currentValue={currentLocation} reset={locationRest} name="location" handleFormValues={handleSubmitLocation} isCreatable={false} options={allLocations} placeHolder='Select location'></SelectWithLocalSearch>
                </div>
                {/* label, name, options, is_mandatory, handleFormValues, placeholder, required = false */}
                <div>
                    <small>Doctor</small>
                    <SelectWithLocalSearch currentValue={currentDoctor} isDisabled={selectedLocation?.doctors?.length ? false : true} name="doctor" handleFormValues={handleLocationDoctor} isCreatable={false} options={selectedLocation?.doctors || []} placeHolder='Select doctor'></SelectWithLocalSearch>
                    {/* <CreatableSearchSelect isDisabled={selectedLocation?.doctors?.length ? false : true} handleSubmit={() => { }} isCreatable={false} handleCreate={() => { }} isSearching={false} setOptionFun={setOptionFun} options={selectedLocation?.doctors || []} placeHolder='Search doctor'></CreatableSearchSelect> */}
                </div>
            </div>
        </div>
    )
}
export default AppointmentFilter

