import React, { useEffect, useMemo, useRef, useState } from 'react'
import PrescriptionBody from './prescriptionBody';
import "./prescription-print.scss"
import PrescriptionHeader from './prescriptionHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faPrint } from '@fortawesome/free-solid-svg-icons';
import ReactToPrint from 'react-to-print';
import PrescriptionFooter from './prescriptionFooter';
import { dateFormat } from '../../../utils/common-funtions/common';
import { get } from '../../../utils/fetchAPI';

const PrescriptionPrint = ({ patientId, visitId, setShowPrint, visitDetails }) => {
    const ref = useRef()
    const [patientDetails, setPatientDetails] = useState()
    const [details, setDetails] = useState()
    const [isHeader, setIsHeader] = useState(true)
    const [isFooter, setIsFooter] = useState(true)
    const [doctorDetails, setDoctorDetails] = useState([])
    let [user] = useState(JSON.parse(localStorage.getItem("user")))

    useEffect(() => {
        if (patientId)
            getPatientDetails(patientId)
        if (user?.user?.type?.toLowerCase() === 'doctor') {
            setDoctorDetails(user?.user)
            setIsHeader(user?.user.details?.config?.prescriptionHeaderInPrint)
            setIsFooter(user?.user.details?.config?.prescriptionFooterInPrint)
        }
        else if (visitDetails?.doctor) {
            getDoctorDetails(visitDetails?.doctor)
        }
    }, []);



    async function getPatientDetails(patientId) {
        await get(`/patient/${patientId}`)
            .then((res) => {
                if (res?.status === 200) {
                    setPatientDetails(res.data)
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    async function getDoctorDetails(doctorId) {
        await get(`/user/${doctorId}`)
            .then((res) => {
                if (res?.status === 200) {
                    setDoctorDetails(res.data)
                    setIsHeader(res.data.details?.config?.prescriptionHeaderInPrint)
                    setIsFooter(res.data.details?.config?.prescriptionFooterInPrint)
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    useMemo(() => {
        if (visitDetails)
            setDetails(visitDetails)
    }, [visitDetails])

    return (
        <>
            <div className="mt-0 pt-0 d-flex justify-content-between p-2">
                <div className='d-flex'>
                    <ReactToPrint
                        trigger={() => <p className=" mt-0 pt-0 mr-2 d-inline-block px-2 rounded" style={{ cursor: "pointer", background: "#13AC81", color: "white" }}>Print <FontAwesomeIcon className="mt-0 pt-0" icon={faPrint} /></p>}
                        content={() => ref.current}
                        onBeforePrint={() => document.title = dateFormat(patientDetails?.updatedAt?.slice(0, 10)) + "-" + patientDetails?.name + "-" + patientDetails?.patientId
                        }
                        onAfterPrint={() => document.title = "Shafa-Emr"}
                    />
                </div>
                <p onClick={() => { setShowPrint(false) }} style={{ cursor: "pointer" }} className="mt-0 pt-0"><FontAwesomeIcon className="mt-0 pt-0" icon={faCircleXmark} /></p>
            </div>
            <div style={{ height: "1700px" }}>
                <div ref={ref} className='full-prescription' style={{ height: "90vh", margin: doctorDetails?.details?.config?.prescriptionMargin || "5px px 5px 5px" }}>
                    <PrescriptionHeader isHeader={isHeader} info={patientDetails} drDetails={doctorDetails || []}></PrescriptionHeader>
                    <PrescriptionBody drDetails={doctorDetails || []} patientDetails={patientDetails} info={details}></PrescriptionBody>
                    <PrescriptionFooter isFooter={isFooter} drDetails={doctorDetails || []}></PrescriptionFooter>
                </div>
            </div>
        </>
    )
}

export default PrescriptionPrint
