import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import AppSidebar from "../components/app-side-bar/app-sidebar";
import sorryPng from "../assets/images/sorry.png"

const PrivateRoute = () => {
  const { isLoggedIn } = useSelector((state) => state.auth);
  const [isMobile, setIsMobile] = useState(false)
  useEffect(() => {
    const userAgent = navigator.userAgent;
    const mobileRegex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    setIsMobile(mobileRegex.test(userAgent))
  })
  return (
    <div className="wrapper" style={{ position: "relative" }}>
      {/* <AppNavBar /> */}
      <AppSidebar />
      {isLoggedIn && isMobile && <di className="text-center my-auto screen-msg">
        <img className="screen-msg-icon" src={sorryPng}></img>
        <p className="p-2">Our application is not optimized for mobile screens. For the best experience, we recommend accessing the application from a computer or laptop.</p>
      </di>}
      {isLoggedIn ? <Outlet /> : <Navigate to="/login" />}

      <aside className="control-sidebar control-sidebar-dark">
        {/* Control sidebar content goes here */}
      </aside>
      {/* <AppFooter /> */}
    </div>
  );
};

export default PrivateRoute;
