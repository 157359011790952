import React, { useEffect, useMemo, useRef, useState } from "react";
import "./appointment.scss";
import { useDispatch, useSelector } from "react-redux";
import { getAppointments } from "../../store/slices/appointmentSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faEye,
    faPenToSquare,
    faTrash,
    faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import CustomModal from "../../components/common/modal/modal";
import CreateAppointment from "../../components/appointment/create-appointment";
import Pagination from "react-js-pagination";
import { deleteAppointment } from "../../service/api/appointment-api";
import { createVisit } from "../../service/api/visit";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { dateFormat, formatDate } from "../../utils/common-funtions/common";
import PrescriptionPrint from "../../components/prescription/prescription-print/prescription-print";
import AppointmentFilter from "../../components/appointment/appointment-filter";
import { put } from "../../utils/fetchAPI";
import AppointmentDetailsShow from "../../components/appointment/appointment-details-show";
import { Toast } from "../../utils/SwalUti";
import { LocalStorageService } from "../../utils/local-storage";

const Appointment = () => {
    const dispatch = useDispatch();
    const [selectedRow, setSelectedRow] = useState();
    const ref = useRef([]);
    const keyRef = useRef()
    const [length, setLength] = useState(50);
    let { isLoading, pagination, allAppointments, noAppointmentFound } =
        useSelector((state) => state.appointment);
    const { currentPage, perPage, total } = pagination;
    const [show, setShow] = useState(false);
    const [searchKey, setSearchKey] = useState("");
    const [showPrint, setShowPrint] = useState(false);
    const [patientId, setPatientId] = useState()
    const [visitId, setVisitId] = useState()
    const [actionModal, setActionModal] = useState(false)
    const [enableButton, setEnableButton] = useState({ delete: false, appointmentDate: false, status: false })
    const navigate = useNavigate()
    const [appointmentUpdatedValue, setAppointmentUpdatedValue] = useState({})
    let filterLocalData = LocalStorageService.get("appointmentFilter")
    

    const utcDate = new Date();
    let bdDate = utcDate.toLocaleDateString('en-US', { timeZone: 'Asia/Dhaka' });
    //let bdDate = utcDate.toLocaleDateString('en-US', {day: '2-digit', month: '2-digit', year: 'numeric',timeZone: 'Asia/Dhaka'});
    bdDate = bdDate.split('/')
    let date = `${bdDate[2]}-${bdDate[0].length == 1 ? ("0" + bdDate[0]) : bdDate[0]}-${bdDate[1]?.length == 1 ? ("0" + bdDate[1]) : bdDate[1]}`

    const utcDate1 = new Date();
    utcDate1.setDate(utcDate1.getDate() + 7);
    let bdDate1 = utcDate1.toLocaleDateString('en-US', { timeZone: 'Asia/Dhaka' });
    bdDate1 = bdDate1.split('/')
    let endDate = `${bdDate1[2]}-${bdDate1[0].length == 1 ? ("0" + bdDate1[0]) : bdDate1[0]}-${bdDate1[1]?.length == 1 ? ("0" + bdDate1[1]) : bdDate1[1]}`

    const [searchParams] = useSearchParams();
    const [filterDate, setFilterDate] = useState({ startDate: filterLocalData?.startDate || searchParams.get('startDate') || date?.slice(0, 10), endDate: filterLocalData?.endDate || searchParams.get('endDate') || endDate?.slice(0, 10) })
    const [currentDate, setCurrentDate] = useState(true)
    const [currentLocation, setCurrentLocation] = useState(filterLocalData?.locationId || searchParams.get('location') || '')
    const [currentDoctor, setCurrentDoctor] = useState(filterLocalData?.doctorId || searchParams.get('doctor') || '')
    const [status, setStatus] = useState(filterLocalData?.status || searchParams.get('status') || '')
    const [appointmentDate, setAppointmentDate] = useState('')
    const [dateChangeModal, setDateChangeModal] = useState(false)
    const [selectedAppointment, setSelectedAppointment] = useState({})
    const location = useLocation()


    useEffect(() => {
        dispatch(
            getAppointments({
                search: "",
                per_page: filterLocalData?.per_page || length,
                current_page: filterLocalData?.current_page || currentPage,
                startDate: filterLocalData?.startDate || filterDate?.startDate,
                endDate: filterLocalData?.endDate || filterDate?.endDate,
                status: filterLocalData?.status || status,
                locationId: filterLocalData?.locationId || currentLocation || '',
                doctorId: filterLocalData?.doctorId || currentDoctor || ""
            })
        );
    }, []);

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            // console.log("event",e,ref.current)
            if (
                selectedRow &&
                ref.current[selectedRow] &&
                !ref.current[selectedRow].contains(e.target)
            ) {
                setSelectedRow(false);
            }
        };
        document.addEventListener("mousedown", checkIfClickedOutside);
        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [selectedRow]);

    const index = (page = 1, search = "", per_page = length, startDate = filterDate.startDate, endDate = filterDate?.endDate, statusVal = status, locationId = currentLocation || '', doctorId = currentDoctor || "") => {
        LocalStorageService.set('appointmentFilter', {
            per_page: per_page,
            current_page: page,
            startDate: startDate,
            endDate: endDate,
            status: statusVal,
            locationId: locationId,
            doctorId: doctorId
        })
        dispatch(
            getAppointments({
                search: search,
                per_page: per_page,
                current_page: page,
                startDate: startDate,
                endDate: endDate,
                status: statusVal,
                locationId: locationId,
                doctorId: doctorId
            })
        );
    };

    const getSearchedAppointmentFun = (value) => {
        setSearchKey(value.trim());
        index(1, value.trim(), length, filterDate.startDate, filterDate?.endDate, status, currentLocation, currentDoctor);
    };

    const handleKeyDown = (event) => {
        if ((event.key == "Enter" && keyRef?.current?.id == "appointment-page")) {
            event.preventDefault();
            getSearchedAppointmentFun(searchKey)
        }
    }

    useEffect(() => {
        if (searchKey)
            document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [searchKey]);

    // const appointmentSearchWithTimeOut = (event) => {
    //     clearTimeout(timeoutIdRef.current);
    //     timeoutIdRef.current = setTimeout(() => { getSearchedAppointmentFun(event) }, 700);
    // };

    const createVisitAndNavigate = (appointment) => {
        createVisit(appointment?._id)
            .then((res) => {
                if (res.status === 200) {
                    navigate(`/appointment/${res?.data?._id}`)
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const deleteAppointmentFun = async (appointment) => {
        await deleteAppointment(appointment?._id)
            .then((res) => {
                if (res.status === 200) {
                    index(currentPage, searchKey, length, filterDate.startDate, filterDate?.endDate, status, currentLocation, currentDoctor)
                    setEnableButton({ ...enableButton, delete: false })
                    Toast.fire({
                        icon: '',
                        title: "Appointment deleted successfully"
                    })
                    setActionModal(false)
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }


    const filterDateFun = (type, startDate, endDate) => {

        if (type == "current-date") {
            setCurrentDate(true)
            setFilterDate({ startDate: date?.slice(0, 10), endDate: endDate })
            LocalStorageService.delete('')
            setStatus('')
            setCurrentLocation("")
            setCurrentDoctor("")
        } else if (type == 'between-date') {
            setCurrentDate(false)
            var date1 = new Date(startDate);
            var date2 = new Date(endDate);
            var diffDays = date2.getDate() - date1.getDate();
            if (diffDays < 0) {
                endDate = startDate
            }
        }
        setFilterDate({ startDate: startDate, endDate: endDate })
        index(currentPage, searchKey, length, startDate, endDate, status, currentLocation, currentDoctor)
    }

    const filterStatusFun = (val) => {
        if (val != "reSet") {
            index(currentPage, searchKey, length, filterDate?.startDate || date?.slice(0, 10), filterDate?.endDate || date?.slice(0, 10), val, currentLocation || "", currentDoctor || "")
        } else {
            setStatus('')
            index(currentPage, searchKey, length, filterDate?.startDate || date?.slice(0, 10), filterDate?.endDate || date?.slice(0, 10), '', currentLocation || "", currentDoctor || "")
        }
    }

    const locationDoctorFilterFun = (type, value) => {
        // console.log("location", type, value?._id)
        // return
        let doctorId = currentDoctor
        let locationId = currentLocation
        if (type == 'location') {
            setCurrentLocation(value?._id)
            locationId = value?._id
        } else if (type == 'doctor') {
            setCurrentDoctor(value?._id)
            doctorId = value?._id
        } else if (type == 'reset') {
            doctorId = ""
            locationId = ""
            setCurrentLocation("")
            setCurrentDoctor("")
        }

        index(currentPage, searchKey, length, filterDate?.startDate || date?.slice(0, 10), filterDate?.endDate || date?.slice(0, 10), status, locationId, doctorId)
    }

    const changeAppointmentDateFun = async (newDate = "") => {

        await put(`appointment/reschedule/${selectedAppointment?._id}`, { date: newDate || appointmentDate })
            .then((res) => {
                if (res.status === 200) {
                    Toast.fire({
                        icon: '',
                        title: "Appointment date change successfully"
                    })
                    setSelectedAppointment({})
                    setDateChangeModal(false)
                    index(currentPage, searchKey, length, filterDate.startDate, filterDate?.endDate, status, currentLocation || "", currentDoctor || "")
                    setEnableButton({ ...enableButton, appointmentDate: false })

                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setDateChangeModal(false)
            });
    }

    const setParamsFun = (status_val = status || "", startDate = filterDate.startDate || "", endDate = filterDate?.endDate || "", location = currentLocation || "", doctor = currentDoctor || "") => {
        const url = `/appointment?startDate=${startDate}&endDate=${endDate}&status=${status_val}${location?.length ? ('&location=' + location) : ""}${doctor?.length ? ('&doctor=' + doctor) : ""}`
        navigate(url)
    }

    useMemo(() => {
        if (status || filterDate.startDate || filterDate?.endDate || currentLocation || currentDoctor)
            setParamsFun()
    }, [status, filterDate.startDate, filterDate?.endDate, currentLocation, currentDoctor])

    return (
        <div>
            {/* -------------- */}
            <section ref={keyRef} id="appointment-page" className="content pt-3 content-wrapper main-content appointment-page-container d-flex">
                <AppointmentFilter locationDoctorFilterFun={locationDoctorFilterFun} filterStatusFun={filterStatusFun} setStatus={setStatus} status={status}
                    setFilterDate={setFilterDate} filterDate={filterDate} filterDateFun={filterDateFun} currentDate={currentDate} current width={"15%"} />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="appointment-add d-flex justify-content-between ">
                                        <button
                                            className="btn appointment-add-button"
                                            onClick={(e) => {
                                                e.preventDefault()
                                                setShow(true);
                                            }}
                                        >
                                            <FontAwesomeIcon
                                                className="appointment-add-button-icon"
                                                icon={faUserPlus}
                                            ></FontAwesomeIcon>{" "}
                                            New appointment{" "}
                                        </button>
                                        <div className="w-50 d-flex">
                                            <input
                                                className="appointment-search w-75"
                                                placeholder="Search appointment"
                                                type="text"
                                                value={searchKey || ""}
                                                onChange={(e) => {
                                                    setSearchKey(e.target.value)
                                                    // appointmentSearchWithTimeOut(e);
                                                }}
                                            ></input>
                                            <button onClick={() => getSearchedAppointmentFun(searchKey)} className="btn my-0 py-1 mx-2 px-3" style={{ backgroundColor: "#0EAC81", color: "white", fontSize: "13px" }}>Search</button>
                                            <button onClick={() => {
                                                setSearchKey("")
                                                index()
                                            }} className="btn my-0 py-1 mx-2 px-3" style={{ backgroundColor: "#0EAC81", color: "white", fontSize: "13px" }}>Reset</button>
                                        </div>
                                    </div>
                                </div>
                                {/* /.card-header */}
                                <div
                                    className="card-body"
                                    style={{ overflowX: "auto", height: "78vh" }}
                                >
                                    <table
                                        id="example2"
                                        className="table table-hover table-sm table-responsive{-sm|-md|-lg|-xl}"
                                    >
                                        <thead>
                                            {/* table heading */}
                                            <tr>
                                                <th>Serial</th>
                                                <th>Patient ID</th>
                                                <th>Patient Name</th>
                                                <th>Appointment Date</th>
                                                <th>Appointment Type</th>
                                                <th>Phone</th>
                                                <th>Gender</th>
                                                <th>Status</th>
                                                <th>Details</th>
                                                {/* <th className="text-center">Print</th> */}
                                                <th className="text-center">Action</th>
                                            </tr>
                                        </thead>
                                        {noAppointmentFound ? (
                                            <small className="d-block ml-1">
                                                No appointment found
                                            </small>
                                        ) : (
                                            <tbody>
                                                {allAppointments.appointments?.map(
                                                    (appointment, index) => {
                                                        return (
                                                            <tr
                                                                key={index}
                                                                className={`${appointment?.status == "present" ? "present" : ""} ${appointment?.status == "completed" ? "active-row" : ""} status-section ${appointment?._id === selectedRow
                                                                    ? "active-row"
                                                                    : "inactive-row"
                                                                    }`}
                                                            // onClick={()=>console.log(appointment,index)}
                                                            >
                                                                {/* <td className="">{index + 1}</td> */}
                                                                <td className="">
                                                                    {appointment?.serial}
                                                                </td>
                                                                <td className="">
                                                                    {appointment?.patient?.patientId}
                                                                </td>
                                                                <td className="">
                                                                    {appointment?.patientName}
                                                                </td>
                                                                <td className="position-relative">
                                                                    {formatDate(appointment?.date?.slice(0, 10))}
                                                                    {/* <p className="date-show">{dateFormat(appointment?.date?.slice(0, 10))}</p>
                                  <input onChange={(e) => {
                                    setSelectedAppointment(appointment)
                                    setAppointmentDate(e.target?.value)
                                    setDateChangeModal(true)
                                  }} value={appointment?.date?.slice(0, 10)} type="date" className="date-pick"></input> */}
                                                                </td>
                                                                <td className="pl-4">
                                                                    {appointment?.type?.toUpperCase()}
                                                                </td>
                                                                <td className="">
                                                                    {appointment?.patientPhone}
                                                                </td>
                                                                <td className="">
                                                                    {appointment?.patientGender}
                                                                </td>
                                                                <td style={{ cursor: "pointer" }}>
                                                                    <p
                                                                        className={`mb-0 pb-0 d-flex ${selectedRow ? "disable-btn" : ""
                                                                            }`}
                                                                        onClick={() => {
                                                                            setSelectedRow(appointment?._id);
                                                                        }}
                                                                    >
                                                                        <span className="pb-1">
                                                                            {appointment?.status}
                                                                        </span>
                                                                        {/* <span
                                      title={`click to change option-section `}
                                      style={{ marginTop: "2px" }}
                                    >
                                      <FontAwesomeIcon
                                        size="xs"
                                        icon={faEllipsisVertical}
                                        className={`ml-2 ${appointment?.status == "present" ? "text-black" : "common-font"} `}
                                      />
                                    </span> */}
                                                                    </p>
                                                                    {/* <div
                                    ref={(el) =>
                                      (ref.current[appointment?._id] = el)
                                    }
                                    className={`status ${appointment?._id === selectedRow
                                      ? "d-block"
                                      : "d-none"
                                      }`}
                                  >
                                    <p
                                      className="text-center option"
                                      onClick={() =>
                                        setStatusFun("Present", appointment)
                                      }
                                    >
                                      Present
                                    </p>
                                    <p
                                      onClick={() =>
                                        setStatusFun("Pending", appointment)
                                      }
                                      className="text-center option"
                                    >
                                      Pending
                                    </p>
                                    <p
                                      onClick={() =>
                                        setStatusFun("canceled", appointment)
                                      }
                                      className="text-center option"
                                    >
                                      Canceled
                                    </p>
                                  </div> */}
                                                                </td>
                                                                <td className="" onClick={() => {
                                                                    createVisitAndNavigate(appointment)
                                                                }}>
                                                                    {/* <Link
                                    // to={`/appointment/${appointment?._id}/${appointment?.patient?._id}`}
                                    // className="table-link "
                                  >
                                    <span className="p-0 m-0">View</span>
                                  </Link> */}
                                                                    <p className="visit-btn text-center">View <FontAwesomeIcon className="ml-1" size="sm"
                                                                        icon={faEye} /></p>
                                                                </td>
                                                                {/* <td className="" onClick={() => { prescriptionPrintFun(appointment) }}>
                                  <p className=" text-center" ><FontAwesomeIcon style={{ color: "#13AC81" }} className="ml-1" size="md"
                                    icon={faPrint} /></p>
                                </td> */}
                                                                <td className='text-center'>
                                                                    {/* <span className='icon'> <FontAwesomeIcon onClick={() => {
                                      deleteAppointmentFun(appointment)
                                    }} icon={faTrash}></FontAwesomeIcon></span> */}
                                                                    <FontAwesomeIcon onClick={() => {
                                                                        setSelectedAppointment(appointment)
                                                                        setActionModal(true)
                                                                    }} icon={faPenToSquare} />
                                                                </td>
                                                            </tr>
                                                        );
                                                    }
                                                )}
                                            </tbody>
                                        )}
                                        {isLoading && <small className="ml-1">Loading...</small>}
                                    </table>
                                </div>
                                {/* /.card-body */}
                                <div className="card-footer">
                                    <div className="float-left">
                                        <div
                                            className="dataTables_info"
                                            id="example2_info"
                                            role="status"
                                            aria-live="polite"
                                        >
                                            <>
                                                <select
                                                    defaultValue={50}
                                                    onChange={(e) => {
                                                        setLength(e.target.value);
                                                        index(1, "", e.target.value, filterDate.startDate, filterDate?.endDate, status, currentLocation, currentDoctor);
                                                    }}
                                                >
                                                    {/* <option value={5}>5 entries </option> */}
                                                    <option value={10}>10 entries</option>
                                                    <option value={20}>20 entries</option>
                                                    <option value={50}>50 entries</option>
                                                    <option value={100}>100 entries</option>
                                                </select>{" "}
                                                &nbsp; Showing {((filterLocalData?.currentPage || currentPage) - 1) * (filterLocalData?.perPage || perPage) + 1} to{" "}
                                                {total > (filterLocalData?.currentPage || currentPage) * (filterLocalData?.perPage || perPage)
                                                    ? currentPage * (filterLocalData?.perPage || perPage)
                                                    : total}{" "}
                                                of {total} entries
                                            </>
                                        </div>
                                    </div>
                                    <div className="float-right">
                                        {noAppointmentFound && !isLoading ? (
                                            ""
                                        ) : (
                                            <div className="d-flex justify-content-center">
                                                <Pagination
                                                    activePage={parseInt((filterLocalData?.currentPage || currentPage))}
                                                    itemsCountPerPage={parseInt((filterLocalData?.perPage || perPage))}
                                                    totalItemsCount={parseInt(total)}
                                                    // pageRangeDisplayed={5}
                                                    onChange={(currentPage) => {
                                                        index(currentPage, "", length);
                                                    }}
                                                    itemClass="page-item"
                                                    linkClass="page-link"
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {/* /.card */}
                        </div>
                        {/* /.col */}
                    </div>
                    {/* /.row */}
                </div>
                {/* /.container-fluid */}
            </section>
            {/*  modal */}
            <CustomModal
                customClass="appointment"
                size="xl"
                show={show}
                setShow={setShow}
                customSize={"size-72"}
                isHeader={false}
                isHeaderHide={true}
            >


                <div className="d-flex align-content-end align-items-end border p-0 m-0">
                    <strong onClick={() => setShow(false)} className="p-0 m-0 ml-auto mx-2 text-danger" style={{ cursor: "pointer" }}>Close</strong>
                </div>
                <h1>

                    <CreateAppointment
                        setShow={setShow}
                        refresh={index}
                    ></CreateAppointment>
                </h1>
            </CustomModal>

            <CustomModal
                size="xl"
                show={showPrint}
                setShow={setShowPrint}
                isHeader={true}
                isHeaderHide={true}
            >
                <PrescriptionPrint setShowPrint={setShowPrint} patientId={patientId} visitId={visitId}></PrescriptionPrint>
            </CustomModal>

            <CustomModal
                size="md"
                show={dateChangeModal}
                setShow={setDateChangeModal}
                isHeader={true}
            >
                <div>
                    <h6 className="text-center pt-3">Appointment date reschedule from <span
                        className="text-danger">{dateFormat(selectedAppointment?.date?.slice(0, 10))}</span> to <span className="text-success">{dateFormat(appointmentDate)}</span>
                        <br />
                        Are you sure ?</h6>
                    <div className="d-flex justify-content-center mb-0 pb-0">
                        <p onClick={() => {
                            changeAppointmentDateFun()
                        }} className="success px-4 mr-2">Yes</p>
                        <p onClick={() => {
                            setDateChangeModal(false)
                        }} className="cancel">No</p>
                    </div>
                </div>
            </CustomModal>
            <CustomModal
                size="md"
                show={actionModal}
                setShow={setActionModal}
                isHeader={true}
                isHeaderHide={true}
            >
                <div className="p-2 pb-3">
                    <div className="m-0  d-flex justify-content-end"><p onClick={() => {
                        setActionModal(false)
                    }} className="cancel d-inline-block m-0">close</p></div>

                    <AppointmentDetailsShow setActionModal={setActionModal} reset={index} setAppointmentUpdatedValue={setAppointmentUpdatedValue}
                        appointmentUpdatedValue={appointmentUpdatedValue} setSelectedAppointment={setSelectedAppointment} selectedAppointmentInfo={selectedAppointment}></AppointmentDetailsShow>

                    <div className="row appointment-details pb-3 mt-2">
                        <p className="col-12 px-3 py-0 my-0 py-2 d-flex"><input checked={enableButton?.delete || ""} className="my-0 py-0 " onClick={() => {
                            setEnableButton({ ...enableButton, 'delete': !enableButton?.delete })
                        }} name="check-1" id="check-1" type="checkbox"></input> <label htmlFor="check-1" className="px-1 py-0 my-0 d-inline" style={{ cursor: "pointer" }}>Delete this
                            appointment</label></p>
                        <p className="col-6 px-3 py-0 my-0 ">  <span onClick={() => {
                            deleteAppointmentFun(selectedAppointment)
                        }} className={`icon ${enableButton?.delete ? "enable" : "disable"} btn btn-danger btn-sm d-inline p-1 px-3 my-0`}>Delete <FontAwesomeIcon
                            icon={faTrash}></FontAwesomeIcon></span></p>
                    </div>
                </div>

            </CustomModal>
        </div>
    );
};

export default Appointment;

