import React, { useEffect, useRef, useState } from "react";
import "./appointment.scss";
import { useDispatch, useSelector } from "react-redux";
import { getAppointments } from "../../store/slices/appointmentSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisVertical,
  faEye,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import CustomModal from "../../components/common/modal/modal";
import CreateAppointment from "../../components/appointment/create-appointment";
import Pagination from "react-js-pagination";
import { appointmentStatusChange, createVisit } from "../../service/api/appointment-api";
import { Link, useNavigate } from "react-router-dom";
import { getDashboardAppointments } from "../../store/slices/dashboardSlice";
import { dateFormat, formatDate } from "../../utils/common-funtions/common";

const HomeAppointmentList = () => {
  const dispatch = useDispatch();
  const [selectedRow, setSelectedRow] = useState();
  const ref = useRef([]);
  const [length, setLength] = useState(5);
  // let { isLoading, pagination, allAppointments, noAppointmentFound } =
  //   useSelector((state) => state.appointment);
  let { isLoading, dashboardAppointments } = useSelector((state) => state.dashboard);
  const [show, setShow] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const navigate = useNavigate()
  const [noAppointmentFound, setNoAppointmentFound] = useState(false)

  useEffect(() => {
    dispatch(
      getDashboardAppointments()
    );
  }, []);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // console.log("event",e,ref.current)
      if (
        selectedRow &&
        ref.current[selectedRow] &&
        !ref.current[selectedRow].contains(e.target)
      ) {
        setSelectedRow(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [selectedRow]);

  const index = () => {
    dispatch(getDashboardAppointments());
  };



  const setStatusFun = async (status, info) => {
    await appointmentStatusChange(info?._id, {
      status: status?.toLowerCase(),
    })
      .then((res) => {
        if (res.status === 200) {
          index();
          setSelectedRow();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const createVisitAndNavigate = async (appointment) => {
    await createVisit(appointment?._id)
      .then((res) => {
        if (res.status === 200) {
          navigate(`/appointment/${res?.data?._id}`)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const handleKeyDown = (event) => {
    // if (event.keyCode == 13) {
    //   event.preventDefault();
    //   setShow(true)
    // }
    // if (event.keyCode == 27) {
    //   event.preventDefault();
    //   setShow(false)
    // }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <div>
      {/* -------------- */}
      <section className="content pt-1">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">

                {/* /.card-header */}
                <div
                  className="card-body"
                  style={{ overflowX: "auto" }}
                >
                  <table
                    id="example2"
                    className="table table-hover table-sm table-responsive{-sm|-md|-lg|-xl}"
                  >
                    <thead>
                      {/* table heading */}
                      <tr>
                        <th>PATIENT NAME</th>
                        <th>APPOINTMENT DATE</th>
                        <th>PHONE</th>
                        <th>GENDER</th>
                        <th>STATUS</th>
                        <th>DETAILS</th>
                      </tr>
                    </thead>
                    {noAppointmentFound ? (
                      <small className="d-block ml-1">
                        No appointment found
                      </small>
                    ) : (
                      <tbody>
                        {dashboardAppointments?.map(
                          (appointment, index) => {
                            return (
                              <tr
                                key={index}
                                className={`${appointment?.status == "present" ? "active-row" : ""} status-section ${appointment?._id === selectedRow
                                  ? "active-row"
                                  : "inactive-row"
                                  }`}
                              // onClick={()=>console.log(appointment,index)}
                              >
                                {/* <td className="hide">{index + 1}</td> */}
                                <td className="hide">
                                  {appointment?.patientName}
                                </td>
                                <td className="hide">
                                  {formatDate(appointment?.createdAt)}
                                </td>
                                <td className="hide">
                                  {appointment?.patientPhone}
                                </td>
                                <td className="hide">
                                  {appointment?.patientGender}
                                </td>
                                <td style={{ cursor: "pointer" }}>
                                  <p
                                    className={`mb-0 pb-0 d-flex ${selectedRow ? "disable-btn" : ""
                                      }`}
                                    onClick={() => {
                                      setSelectedRow(appointment?._id);
                                    }}
                                  >
                                    <span className="pb-1">
                                      {appointment?.status}
                                    </span>
                                    <span
                                      title={`click to change option-section `}
                                      style={{ marginTop: "2px" }}
                                    >
                                      <FontAwesomeIcon
                                        size="xs"
                                        icon={faEllipsisVertical}
                                        className={`ml-2 ${appointment?.status == "present" ? "text-black" : "common-font"} `}
                                      />
                                    </span>
                                  </p>
                                  <div
                                    ref={(el) =>
                                      (ref.current[appointment?._id] = el)
                                    }
                                    className={`status ${appointment?._id === selectedRow
                                      ? "d-block"
                                      : "d-none"
                                      }`}
                                  >
                                    <p
                                      className="text-center option"
                                      onClick={() =>
                                        setStatusFun("Present", appointment)
                                      }
                                    >
                                      Present
                                    </p>
                                    <p
                                      onClick={() =>
                                        setStatusFun("Pending", appointment)
                                      }
                                      className="text-center option"
                                    >
                                      Pending
                                    </p>
                                  </div>
                                </td>
                                <td onClick={() => { createVisitAndNavigate(appointment) }}>
                                  {/* <Link
                                    // to={`/appointment/${appointment?._id}/${appointment?.patient?._id}`}
                                    // className="table-link "
                                  >
                                    <span className="p-0 m-0">View</span>
                                  </Link> */}
                                  <p className="visit-btn text-center">View <FontAwesomeIcon className="ml-1" size="sm"
                                    icon={faEye} /></p>
                                </td>
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    )}
                    {isLoading && <small className="ml-1">Loading...</small>}
                  </table>
                </div>
                {/* /.card-body */}
              </div>
              {/* /.card */}
            </div>
            {/* /.col */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container-fluid */}
      </section>
      {/*  modal */}
      <CustomModal
        customClass="appointment"
        size="xl"
        show={show}
        setShow={setShow}
        customSize={"size-72"}
      >
        <h1>
          <CreateAppointment
            setShow={setShow}
            refresh={index}
          ></CreateAppointment>
        </h1>
      </CustomModal>
    </div>
  );
};

export default HomeAppointmentList;
